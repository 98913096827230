@font-face {
  font-family: 'Teletext2';
  src: url("/assets/teletext2.woff2") format("woff2"), url("/assets/teletext2.woff") format("woff"), url("/assets/teletext2.otf") format("opentype"), url("/assets/teletext2.ttf") format("truetype");
}

@font-face {
  font-family: 'Teletext4';
  src: url("/assets/teletext4.woff2") format("woff2"), url("/assets/teletext4.woff") format("woff"), url("/assets/teletext4.otf") format("opentype"), url("/assets/teletext4.ttf") format("truetype");
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0;
  margin: 0;
  font-size: 200%;
  color: #ffffff;
  background-color: #302010;
  font-family: "Teletext2", "Arial", "Helvetica", sans-serif;
  overflow-x: hidden;
}

canvas {
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
}

.u-show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

h1 {
  top: 0;
  left: 0;
  pointer-events: none;
}

.input {
  padding: 0 8px;
  font-size: 100%;
  font-family: "Teletext2", "Arial", "Helvetica", sans-serif;
  border: 4px solid transparent;
  border-radius: 3px;
}

.input:focus {
  border-color: #00ffff;
}

.input--page-number {
  width: 100px;
  height: 50px;
}

.select {
  padding: 4px 12px;
  font-size: 100%;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
}

.select:focus, .select:hover {
  outline: 0;
}

label {
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
  font-weight: 700;
}

button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
  user-select: none;
}

button:focus, button:hover {
  outline: 0;
}

.button {
  border-radius: 8px;
  padding: 10px;
  height: 100px;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  font-size: 100%;
}

.button--keypad {
  border: 1px solid #acc4d6;
  background-color: #f0f4f8;
  background-image: linear-gradient(to bottom, #f0f4f8, #b6cbdb);
  box-shadow: inset 0 -8px 0 0 #8aabc5, inset -2px 0 0 0 #8F8F8F, inset 3px 0 0 0 #B5B5B5, 0 6px 4px 0 #C7C7BB;
}

.button--keypad:hover {
  border: 1px solid #9bb7ce;
  background-color: #dfe8ef;
  background-image: linear-gradient(to bottom, #dfe8ef, #a5bfd3);
}

.button--red {
  border: 1px solid #cc0000;
  background-color: #ff3333;
  background-image: linear-gradient(to bottom, #ff3333, #db0000);
  box-shadow: inset 0 -8px 0 0 #990000, inset -2px 0 0 0 #8F8F8F, inset 3px 0 0 0 #B5B5B5, 0 6px 4px 0 #C7C7BB;
}

.button--red:hover {
  border: 1px solid #b30000;
  background-color: #ff1a1a;
  background-image: linear-gradient(to bottom, #ff1a1a, #c20000);
}

.button--green {
  border: 1px solid #00cc00;
  background-color: #33ff33;
  background-image: linear-gradient(to bottom, #33ff33, #00db00);
  box-shadow: inset 0 -8px 0 0 #009900, inset -2px 0 0 0 #8F8F8F, inset 3px 0 0 0 #B5B5B5, 0 6px 4px 0 #C7C7BB;
}

.button--green:hover {
  border: 1px solid #00b300;
  background-color: #1aff1a;
  background-image: linear-gradient(to bottom, #1aff1a, #00c200);
}

.button--yellow {
  border: 1px solid #cccc00;
  background-color: #ffff33;
  background-image: linear-gradient(to bottom, #ffff33, #dbdb00);
  box-shadow: inset 0 -8px 0 0 #999900, inset -2px 0 0 0 #8F8F8F, inset 3px 0 0 0 #B5B5B5, 0 6px 4px 0 #C7C7BB;
}

.button--yellow:hover {
  border: 1px solid #b3b300;
  background-color: #ffff1a;
  background-image: linear-gradient(to bottom, #ffff1a, #c2c200);
}

.button--cyan {
  border: 1px solid #00cccc;
  background-color: #33ffff;
  background-image: linear-gradient(to bottom, #33ffff, #00dbdb);
  box-shadow: inset 0 -8px 0 0 #009999, inset -2px 0 0 0 #8F8F8F, inset 3px 0 0 0 #B5B5B5, 0 6px 4px 0 #C7C7BB;
}

.button--cyan:hover {
  border: 1px solid #00b3b3;
  background-color: #1affff;
  background-image: linear-gradient(to bottom, #1affff, #00c2c2);
}

.centre-div {
  margin: auto;
  position: absolute;
  top: 0;
  visibility: visible;
}

.grabButton {
  margin: 0 auto;
  width: 600px;
  height: 550px;
  position: absolute;
  top: 0;
}

.controlsButton {
  position: relative;
  padding: initial;
  width: auto;
  height: auto;
  border: none;
  box-sizing: border-box;
  font-size: 32px;
  background: transparent;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
  border-left: 8px solid #ffffff;
  border-right: 8px solid #ffffff;
}

.controlsButton > span {
  display: block;
  padding: 40px;
  margin: -8px 0;
  color: #ffffff;
  border-top: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
}

@media only screen and (max-height: 900px) {
  .controlsButton > span {
    padding: 20px 40px;
  }
}

.controlsButton::before, .controlsButton::after,
.controlsButton > span::before,
.controlsButton > span::after {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ffffff;
}

.controlsButton::before {
  top: -5px;
  left: -5px;
}

.controlsButton::after {
  top: -5px;
  right: -5px;
}

.controlsButton > span::before {
  bottom: -5px;
  left: -5px;
}

.controlsButton > span::after {
  bottom: -5px;
  right: -5px;
}

#logo {
  position: absolute;
  height: 51px;
  width: 226px;
  top: 0;
  left: 0;
}

#logo svg {
  width: 100%;
}

#logo svg path {
  fill: #ffffff;
}

#menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

#menu #menuButton {
  width: 64px;
  height: 64px;
  padding: 16px;
  background: none;
  border: 0;
}

#menu #menuContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: -500px;
  padding: 16px 16px 16px 32px;
  transition: margin-right 0.5s ease-in-out;
}

[data-menu-open="true"] #menu #menuContent {
  margin-right: 0;
}

#menu .menuIcon {
  width: 100%;
  height: 100%;
}

#menu .menuIcon .c {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
  transform: translate(0, 0);
  transition: transform 0.2s linear;
}

[data-menu-open="true"] #menu .menuIcon .c1-2 {
  transform: translate(0, 6.3px);
}

[data-menu-open="true"] #menu .menuIcon .c1-3 {
  transform: translate(0, 12.6px);
}

[data-menu-open="true"] #menu .menuIcon .c1-4 {
  transform: translate(0, 6.3px);
}

[data-menu-open="true"] #menu .menuIcon .c2-1 {
  transform: translate(12.6px, 0);
}

[data-menu-open="true"] #menu .menuIcon .c2-2 {
  transform: translate(6.3px, 0);
}

[data-menu-open="true"] #menu .menuIcon .c2-4 {
  transform: translate(-6.3px, 0);
}

[data-menu-open="true"] #menu .menuIcon .c2-5 {
  transform: translate(-12.6px, 0);
}

[data-menu-open="true"] #menu .menuIcon .c3-2 {
  transform: translate(0, -6.3px);
}

[data-menu-open="true"] #menu .menuIcon .c3-3 {
  transform: translate(0, -12.6px);
}

[data-menu-open="true"] #menu .menuIcon .c3-4 {
  transform: translate(0, -6.3px);
}

#menu .menuSection {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 8px 0;
}

#menu .menuSection button {
  width: 240px;
  min-height: 100px;
}

@media only screen and (max-height: 900px) {
  #menu .menuSection button {
    min-height: 80px;
  }
}

#menu #instructionsSelect button {
  margin: 20px 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#menu #instructionsSelect button:first-child {
  margin-top: 0;
}

#menu #instructionsSelect button:last-child {
  margin-bottom: 0;
}

#menu #grabSelect {
  align-items: flex-end;
  position: relative;
}

#menu #grabSelect #grabSelectLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 260px;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

#menu #grabSelect #grabSelectLinks li {
  margin: 0;
  padding: 12px 0;
}

#menu #grabSelect #grabSelectLinks li:first-child {
  padding-top: 0;
}

#menu #grabSelect #grabSelectLinks li:last-child {
  padding-bottom: 0;
}

#menu #grabSelect #grabSelectLinks li a {
  display: inline-block;
  padding: 7px 10px;
  text-align: right;
  background-color: #bb5000;
  color: #ffffff;
}

#menu #grabSelect #grabSelectLinks[data-visible="false"] {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
}

#menu #grabSelect #grabSelectLinks[data-visible="true"] {
  max-height: 100%;
  opacity: 1;
  pointer-events: all;
}

#settings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 20px;
}

.settingsRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;
}

.settingsRow label {
  margin-right: 16px;
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
}

.settingsRow select {
  width: 240px;
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
}

#menu #instructionsSelect {
  display: none;
}

#wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#controls {
  width: 100%;
  margin-top: 40px;
}

#fastextControls {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#fastextControls button {
  flex-basis: 100%;
  margin: 0 10px;
}

#fastextControls button:first-child {
  margin-left: 0;
}

#fastextControls button:last-child {
  margin-right: 0;
}

#controlsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;
}

#controlsRow label {
  margin-right: 16px;
}

#controlsRow .select--service {
  box-sizing: content-box;
  height: 50px;
}

[data-scale="1"] #controlsRow .select--service {
  max-width: 172px;
}

#controlsRow #pageNumberControls,
#controlsRow #serviceSelectorControls {
  display: flex;
  align-items: center;
}

#controlsRow #randomPage {
  width: 58px;
  height: 58px;
  padding: 14px 0 14px 14px;
  background: transparent;
}

#controlsRow #randomPage svg {
  width: 100%;
  height: 100%;
}

#controlsRow #randomPage svg path {
  fill: #ffffff;
}

#keypadControls {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#keypadControls button {
  flex-basis: 100%;
  margin: 0 10px;
}

#keypadControls button:first-child {
  margin-left: 0;
}

#keypadControls button:last-child {
  margin-right: 0;
}

#description {
  width: 100%;
  margin: 40px 0;
  font-size: 100%;
  text-align: left;
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
  line-height: 1.3;
}

[data-scale="1"] #description {
  max-width: 576px;
}

[data-scale="1.5"] #description {
  max-width: 864px;
}

[data-scale="2"] #description {
  max-width: 1152px;
}

#credit {
  position: fixed;
  bottom: 16px;
  right: 16px;
  margin-right: -500px;
  font-family: "Teletext4", "Arial", "Helvetica", sans-serif;
  color: #ffffff;
  opacity: 0.2;
  transition: margin-right 0.5s ease-in-out 0.1s, opacity 0.2s ease-in-out;
}

#credit a {
  color: #ffffff;
}

#credit:hover, #credit:focus {
  opacity: 1;
}

[data-menu-open="true"] #credit {
  display: block;
  margin-right: 0;
}

@media only screen and (max-height: 900px) {
  #credit {
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 60px;
    opacity: 1;
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ffff00, 0 0 32px #ffff00, 0 0 48px #ffff00;
  }
}

.manifest {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.manifest .manifestContent {
  display: none;
  position: fixed;
  width: 864px;
  height: 682px;
  top: calc(50% - (682px / 2));
  left: calc(50% - (864px / 2));
  background: #0000ff;
  padding: 40px;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  .manifest .manifestContent {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 1000;
  }
}

.manifest .manifestContent header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.manifest .manifestContent header h2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: -6px 32px 16px 0;
  font-size: 42px;
}

@media only screen and (max-width: 900px) {
  .manifest .manifestContent header h2 {
    flex-direction: column;
    align-items: initial;
  }
}

.manifest .manifestContent header h2 span {
  display: inline-flex;
}

.manifest .manifestContent header h2 span#lastUpdated {
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 900px) {
  .manifest .manifestContent header h2 span#lastUpdated {
    margin: 10px 0;
  }
}

.manifest .manifestContent header #manifestCloseButton {
  width: 64px;
  height: 64px;
  padding: 16px;
  margin: -16px -16px 0 0;
  background: none;
  border: 0;
}

.manifest .manifestContent header #manifestCloseButton .menuIcon {
  width: 100%;
  height: 100%;
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
  transform: translate(0, 0);
  transition: transform 0.2s linear;
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c1-2 {
  transform: translate(0, 6.3px);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c1-3 {
  transform: translate(0, 12.6px);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c1-4 {
  transform: translate(0, 6.3px);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c2-1 {
  transform: translate(12.6px, 0);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c2-2 {
  transform: translate(6.3px, 0);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c2-4 {
  transform: translate(-6.3px, 0);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c2-5 {
  transform: translate(-12.6px, 0);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c3-2 {
  transform: translate(0, -6.3px);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c3-3 {
  transform: translate(0, -12.6px);
}

.manifest .manifestContent header #manifestCloseButton .menuIcon .c3-4 {
  transform: translate(0, -6.3px);
}

.manifest .manifestContent header #manifestCloseButton:hover .menuIcon .c, .manifest .manifestContent header #manifestCloseButton:focus .menuIcon .c {
  fill: #ffff00;
  stroke: #ffff00;
}

.manifest .manifestContent .manifestContentInner {
  overflow-y: auto;
}

.manifest .manifestContent .manifestContentInner ul {
  margin: 0;
  padding: 0 16px 0 0;
  list-style: square;
}

.manifest .manifestContent .manifestContentInner ul li {
  font-size: 0;
}

.manifest .manifestContent .manifestContentInner ul li a {
  display: block;
  position: relative;
  margin: 4px 0 4px 46px;
  padding: 6px 0;
  font-size: 22px;
  color: #ffffff;
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  .manifest .manifestContent .manifestContentInner ul li a {
    margin-left: 32px;
  }
}

.manifest .manifestContent .manifestContentInner ul li a:before {
  content: '\0025AB';
  position: absolute;
  top: -23px;
  left: -34px;
  font-size: 70px;
  color: #ffffff;
}

.manifest .manifestContent .manifestContentInner ul li a:hover, .manifest .manifestContent .manifestContentInner ul li a:focus {
  color: #ffff00;
  text-decoration: underline;
}

.manifest .manifestContent .manifestContentInner ul li a dl {
  margin: 0;
}

.manifest .manifestContent .manifestContentInner ul li a dl dt,
.manifest .manifestContent .manifestContentInner ul li a dl dd {
  display: inline-block;
  vertical-align: top;
}

.manifest .manifestContent .manifestContentInner ul li a dl dt {
  width: 100px;
}

.manifest .manifestContent .manifestContentInner ul li a dl dd {
  margin: 0;
  width: calc(100% - 100px);
}

.manifest .manifestContent .manifestContentInner ul li.selected {
  background: #000000;
}

.manifest .manifestContent .manifestContentInner ul li.selected a:before {
  content: '\0025AA';
}

.manifest--visible {
  display: flex;
  z-index: 1000;
}

.manifest--visible .manifestContent {
  display: flex;
  flex-direction: column;
}

.instructions {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.instructions .instructionsContent {
  display: none;
  position: fixed;
  background: #0000ff;
  padding: 40px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1800px) {
  .instructions .instructionsContent {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }
}

@media only screen and (max-width: 1379px) {
  .instructions .instructionsContent {
    overflow: auto;
  }
}

.instructions .instructionsContent header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instructions .instructionsContent header h2 {
  margin: -6px 0 16px 0;
  font-size: 42px;
}

.instructions .instructionsContent header h2 a {
  font-size: 22px;
}

.instructions .instructionsContent header #instructionsCloseButton {
  width: 64px;
  height: 64px;
  padding: 16px;
  margin: -16px -16px 0 0;
  background: none;
  border: 0;
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon {
  width: 100%;
  height: 100%;
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
  transform: translate(0, 0);
  transition: transform 0.2s linear;
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c1-2 {
  transform: translate(0, 6.3px);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c1-3 {
  transform: translate(0, 12.6px);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c1-4 {
  transform: translate(0, 6.3px);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c2-1 {
  transform: translate(12.6px, 0);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c2-2 {
  transform: translate(6.3px, 0);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c2-4 {
  transform: translate(-6.3px, 0);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c2-5 {
  transform: translate(-12.6px, 0);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c3-2 {
  transform: translate(0, -6.3px);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c3-3 {
  transform: translate(0, -12.6px);
}

.instructions .instructionsContent header #instructionsCloseButton .menuIcon .c3-4 {
  transform: translate(0, -6.3px);
}

.instructions .instructionsContent header #instructionsCloseButton:hover .menuIcon .c, .instructions .instructionsContent header #instructionsCloseButton:focus .menuIcon .c {
  fill: #ffff00;
  stroke: #ffff00;
}

.instructions .instructionsContent .instructionsContentInner {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1800px) {
  .instructions .instructionsContent .instructionsContentInner {
    flex-wrap: wrap;
  }
}

.instructions .instructionsContent .instructionsContentInner .column {
  width: 33.3%;
}

.instructions .instructionsContent .instructionsContentInner .column:nth-child(1) {
  width: 440px;
}

@media only screen and (max-width: 1800px) {
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(1) {
    width: 50%;
  }
}

@media only screen and (max-width: 1379px) {
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(1) {
    width: 100%;
  }
}

.instructions .instructionsContent .instructionsContentInner .column:nth-child(2) {
  width: 660px;
}

@media only screen and (max-width: 1800px) {
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(2) {
    width: 50%;
  }
}

@media only screen and (max-width: 1379px) {
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(2) {
    width: 100%;
  }
}

.instructions .instructionsContent .instructionsContentInner .column:nth-child(3) {
  width: 580px;
}

.instructions .instructionsContent .instructionsContentInner .column:nth-child(3) .columnInner:last-child {
  margin-top: 50px;
}

@media only screen and (max-width: 1800px) {
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(3) {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
  }
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(3) .columnInner {
    width: 50%;
  }
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(3) .columnInner:last-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1379px) {
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(3) {
    flex-direction: column;
    width: 100%;
  }
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(3) .columnInner {
    width: 100%;
  }
  .instructions .instructionsContent .instructionsContentInner .column:nth-child(3) .columnInner:last-child {
    margin-top: 30px;
  }
}

.instructions .instructionsContent .instructionsContentInner .column h3 {
  font-size: 24px;
}

.instructions .instructionsContent .instructionsContentInner .column table {
  margin-bottom: 50px;
}

.instructions .instructionsContent .instructionsContentInner .column table.compact, .instructions .instructionsContent .instructionsContentInner .column table:last-child {
  margin-bottom: 0;
}

.instructions .instructionsContent .instructionsContentInner .column table tbody td {
  font-size: 20px;
}

.instructions .instructionsContent .instructionsContentInner .column table tbody td:first-child {
  width: 72px;
}

.instructions .instructionsContent .instructionsContentInner .column table tbody td.wide {
  width: 180px;
}

.instructions .instructionsContent .instructionsContentInner .column table tbody td.ultrawide {
  width: 400px;
}

.instructions .instructionsContent .instructionsContentInner .column table tbody + tbody tr:first-child td {
  padding-top: 20px;
}

.instructions .instructionsContent .instructionsContentInner .column p {
  font-size: 22px;
}

.instructions .instructionsContent .instructionsContentInner .column kbd {
  display: inline-block;
  min-width: 50px;
  border: 1px solid #ffffff;
  text-align: center;
  padding: 3px;
  font-family: "Teletext2", "Arial", "Helvetica", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.instructions .instructionsContent p {
  margin: 18px 0;
}

.instructions .instructionsContent a {
  color: #ffffff;
}

.instructions .instructionsContent a:hover, .instructions .instructionsContent a:focus {
  color: #ffff00;
}

.instructions--visible {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.instructions--visible .instructionsContent {
  display: flex;
  flex-direction: column;
}

.about {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.about .aboutContent {
  display: none;
  position: fixed;
  width: 864px;
  background: #0000ff;
  padding: 40px;
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  .about .aboutContent {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
  }
}

.about .aboutContent header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.about .aboutContent header h2 {
  margin: -6px 0 16px 0;
  font-size: 42px;
}

.about .aboutContent header #aboutCloseButton {
  width: 64px;
  height: 64px;
  padding: 16px;
  margin: -16px -16px 0 0;
  background: none;
  border: 0;
}

.about .aboutContent header #aboutCloseButton .menuIcon {
  width: 100%;
  height: 100%;
}

.about .aboutContent header #aboutCloseButton .menuIcon .c {
  fill: #ffffff;
  stroke: #ffffff;
  stroke-width: 1px;
  transform: translate(0, 0);
  transition: transform 0.2s linear;
}

.about .aboutContent header #aboutCloseButton .menuIcon .c1-2 {
  transform: translate(0, 6.3px);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c1-3 {
  transform: translate(0, 12.6px);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c1-4 {
  transform: translate(0, 6.3px);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c2-1 {
  transform: translate(12.6px, 0);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c2-2 {
  transform: translate(6.3px, 0);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c2-4 {
  transform: translate(-6.3px, 0);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c2-5 {
  transform: translate(-12.6px, 0);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c3-2 {
  transform: translate(0, -6.3px);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c3-3 {
  transform: translate(0, -12.6px);
}

.about .aboutContent header #aboutCloseButton .menuIcon .c3-4 {
  transform: translate(0, -6.3px);
}

.about .aboutContent header #aboutCloseButton:hover .menuIcon .c, .about .aboutContent header #aboutCloseButton:focus .menuIcon .c {
  fill: #ffff00;
  stroke: #ffff00;
}

.about .aboutContent p {
  margin: 18px 0;
}

.about .aboutContent p:last-child {
  margin-bottom: 0;
}

.about .aboutContent p a {
  color: #ffffff;
}

.about .aboutContent p a:hover, .about .aboutContent p a:focus {
  color: #ffff00;
}

.about--visible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about--visible .aboutContent {
  display: flex;
  flex-direction: column;
}

#zapperWrapperStandard,
#zapperWrapperCompact {
  display: none;
  font-size: 0;
}

#zapperWrapperCompact {
  background: #333333;
}

.zapperButton {
  cursor: pointer;
}

[data-controls="zapper"][data-display="standard"], [data-controls="zapper"][data-display="fitscreen"] {
  background: #000000;
}

[data-controls="zapper"] #gridSelect,
[data-controls="zapper"] #instructionsSelect,
[data-controls="zapper"] #grabSelect,
[data-controls="zapper"] #controls {
  display: none;
}

[data-controls="zapper"] #wrapper {
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
  [data-controls="zapper"] #wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  [data-controls="zapper"] #wrapper {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  [data-controls="zapper"] #wrapper {
    flex-direction: row;
  }
}

[data-controls="zapper"] #zapperWrapperStandard {
  display: block;
  margin-left: 80px;
}

[data-controls="zapper"] #zapperWrapperStandard svg {
  width: auto;
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  [data-controls="zapper"] #zapperWrapperStandard {
    display: none;
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  [data-controls="zapper"] #zapperWrapperStandard {
    display: block;
  }
}

[data-controls="zapper"] #zapperWrapperCompact svg {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 244px;
}

@media only screen and (max-width: 1023px) {
  [data-controls="zapper"] #zapperWrapperCompact {
    padding-top: 16px;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  [data-controls="zapper"] #zapperWrapperCompact {
    padding-bottom: 66px;
  }
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  [data-controls="zapper"] #zapperWrapperCompact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  [data-controls="zapper"] #zapperWrapperCompact {
    display: none;
  }
}

[data-controls="zapper"][data-scale="1"] #zapperWrapperStandard {
  margin-left: calc(40px * 1);
}

[data-controls="zapper"][data-scale="1"] #zapperWrapperStandard svg {
  height: calc(550px * 1);
}

[data-controls="zapper"][data-scale="1.5"] #zapperWrapperStandard {
  margin-left: calc(40px * 1.5);
}

[data-controls="zapper"][data-scale="1.5"] #zapperWrapperStandard svg {
  height: calc(550px * 1.5);
}

[data-controls="zapper"][data-scale="2"] #zapperWrapperStandard {
  margin-left: calc(40px * 2);
}

[data-controls="zapper"][data-scale="2"] #zapperWrapperStandard svg {
  height: calc(550px * 2);
}

[data-controls="zapper"][data-display="fitscreen"] #zapperWrapperStandard, [data-controls="zapper"][data-display="fullscreen"] #zapperWrapperStandard {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: calc(40px * 2);
  height: calc(100% - (40px * 2));
}

[data-controls="zapper"][data-display="fitscreen"] #zapperWrapperStandard svg, [data-controls="zapper"][data-display="fullscreen"] #zapperWrapperStandard svg {
  height: calc(100% - (40px * 2));
}

@media only screen and (max-width: 1279px) and (orientation: landscape) {
  [data-controls="zapper"][data-display="fitscreen"] #zapperWrapperStandard, [data-controls="zapper"][data-display="fullscreen"] #zapperWrapperStandard {
    margin: 0 20px;
  }
}

[data-controls="minimal"], [data-controls="minimal"][data-display="standard"], [data-controls="minimal"][data-display="fitscreen"] {
  background: #000000;
}

[data-controls="minimal"] #gridSelect,
[data-controls="minimal"] #instructionsSelect,
[data-controls="minimal"] #grabSelect,
[data-controls="minimal"] #controls {
  display: none;
}

[data-controls="minimal"] #wrapper {
  flex-direction: row;
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
  [data-controls="minimal"] #wrapper {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) and (orientation: landscape) {
  [data-controls="minimal"] #wrapper {
    flex-direction: row;
  }
}

[data-controls="bigscreen"], [data-controls="bigscreen"][data-display="standard"], [data-controls="bigscreen"][data-display="fitscreen"] {
  background: #000000;
}

[data-controls="bigscreen"] #instructionsSelect,
[data-controls="bigscreen"] #controls,
[data-controls="bigscreen"] #cast {
  display: none;
}

[data-controls="bigscreen"] #wrapper {
  width: 100%;
  flex-direction: row;
}

[data-controls="bigscreen"] #qrWrapper {
  width: 100%;
}

/* display modes */
[data-display="standard"] {
  background-color: #302010;
}

[data-display="fitscreen"] {
  background-color: #302010;
}

[data-display="fitscreen"] .settingsRow--scale {
  display: none;
}

[data-display="fullscreen"] {
  background-color: #000000;
}

[data-display="fullscreen"] #wrapper {
  justify-content: center;
}

[data-display="fullscreen"] #logo {
  display: none;
}

[data-display="fullscreen"] #gridSelect,
[data-display="fullscreen"] #instructionsSelect,
[data-display="fullscreen"] #grabSelect,
[data-display="fullscreen"] #controls,
[data-display="fullscreen"] .settingsRow--scale {
  display: none;
}

[data-controls="zapper"] #logo,
[data-controls="zapper"] #menuButton,
[data-controls="minimal"] #logo,
[data-controls="minimal"] #menuButton,
[data-display="fitscreen"] #logo,
[data-display="fitscreen"] #menuButton,
[data-display="fullscreen"] #logo,
[data-display="fullscreen"] #menuButton {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}

[data-controls="zapper"] #logo:hover,
[data-controls="zapper"] #menuButton:hover,
[data-controls="minimal"] #logo:hover,
[data-controls="minimal"] #menuButton:hover,
[data-display="fitscreen"] #logo:hover,
[data-display="fitscreen"] #menuButton:hover,
[data-display="fullscreen"] #logo:hover,
[data-display="fullscreen"] #menuButton:hover {
  opacity: 1;
}

[data-controls="zapper"][data-menu-open="true"] #menuButton,
[data-controls="minimal"][data-menu-open="true"] #menuButton,
[data-display="fitscreen"][data-menu-open="true"] #menuButton,
[data-display="fullscreen"][data-menu-open="true"] #menuButton {
  opacity: 1;
}

[data-display="fullscreen"] #menuButton {
  opacity: 0;
}

#menu #manifestSelect,
#randomPage,
.settingsRow--autoplay {
  display: none;
}

[data-service-manifest="true"] #menu #manifestSelect,
[data-service-manifest="true"] .settingsRow--autoplay {
  display: flex;
}

[data-service-manifest="true"] #randomPage {
  display: inline-block;
}

#menu #gridSelect {
  display: none;
}

[data-service-editable="true"] #menu #instructionsSelect {
  display: flex;
  flex-direction: column;
}

[data-service-editable="true"] #menu #gridSelect {
  display: flex;
}

[data-service-editable="true"] #menu #gridSelect .controlsButton .gridSelectButtonText--show {
  display: inline;
}

[data-service-editable="true"] #menu #gridSelect .controlsButton .gridSelectButtonText--hide {
  display: none;
}

[data-service-editable="true"] #menu .settingsRow--autoplay {
  display: none !important;
}

[data-service-editable="true"][data-grid="true"] #menu #gridSelect .controlsButton .gridSelectButtonText--show {
  display: none;
}

[data-service-editable="true"][data-grid="true"] #menu #gridSelect .controlsButton .gridSelectButtonText--hide {
  display: inline;
}

[data-display="fitscreen"] #menu::before,
[data-display="fullscreen"] #menu::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  margin-right: -64px;
  z-index: -1;
  backdrop-filter: url(/assets/pixelate.svg#pixelate) grayscale(100%) blur(2px);
  transition: margin-right 0.4s ease-in-out;
}

[data-menu-open="true"] #menu::before {
  margin-right: 0;
}

[data-display="fitscreen"] #menu .menuSection button,
[data-display="fullscreen"] #menu .menuSection button {
  backdrop-filter: blur(6px) brightness(50%);
}

[data-display="fitscreen"] #menu .menuSection button:hover, [data-display="fitscreen"] #menu .menuSection button:focus,
[data-display="fullscreen"] #menu .menuSection button:hover,
[data-display="fullscreen"] #menu .menuSection button:focus {
  backdrop-filter: blur(10px) brightness(25%);
}

[data-display="fitscreen"] #menu .settingsRow:nth-child(1) label,
[data-display="fullscreen"] #menu .settingsRow:nth-child(1) label {
  text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff0000, 0 0 32px #ff0000, 0 0 48px #ff0000;
}

[data-display="fitscreen"] #menu .settingsRow:nth-child(2) label,
[data-display="fullscreen"] #menu .settingsRow:nth-child(2) label {
  text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #00ff00, 0 0 32px #00ff00, 0 0 48px #00ff00;
}

[data-display="fitscreen"] #menu .settingsRow:nth-child(3) label,
[data-display="fullscreen"] #menu .settingsRow:nth-child(3) label {
  text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #0000ff, 0 0 32px #0000ff, 0 0 48px #0000ff;
}

[data-display="fitscreen"] #menu .settingsRow:nth-child(4) label,
[data-display="fullscreen"] #menu .settingsRow:nth-child(4) label {
  text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff00ff, 0 0 32px #ff00ff, 0 0 48px #ff00ff;
}

[data-display="fitscreen"] #logo svg .c,
[data-display="fullscreen"] #logo svg .c {
  opacity: 0;
}

[data-display="fitscreen"] #logo svg .c1,
[data-display="fullscreen"] #logo svg .c1 {
  opacity: 1;
}

@media only screen and (max-width: 1384px) {
  [data-scale="1"] #menu::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right: -64px;
    z-index: -1;
    backdrop-filter: url(/assets/pixelate.svg#pixelate) grayscale(100%) blur(2px);
    transition: margin-right 0.4s ease-in-out;
  }
  [data-menu-open="true"] #menu::before {
    margin-right: 0;
  }
  [data-scale="1"] #menu .menuSection button {
    backdrop-filter: blur(6px) brightness(50%);
  }
  [data-scale="1"] #menu .menuSection button:hover, [data-scale="1"] #menu .menuSection button:focus {
    backdrop-filter: blur(10px) brightness(25%);
  }
  [data-scale="1"] #menu .settingsRow:nth-child(1) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff0000, 0 0 32px #ff0000, 0 0 48px #ff0000;
  }
  [data-scale="1"] #menu .settingsRow:nth-child(2) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #00ff00, 0 0 32px #00ff00, 0 0 48px #00ff00;
  }
  [data-scale="1"] #menu .settingsRow:nth-child(3) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #0000ff, 0 0 32px #0000ff, 0 0 48px #0000ff;
  }
  [data-scale="1"] #menu .settingsRow:nth-child(4) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff00ff, 0 0 32px #ff00ff, 0 0 48px #ff00ff;
  }
  [data-scale="1"] #logo svg .c {
    opacity: 0;
  }
  [data-scale="1"] #logo svg .c1 {
    opacity: 1;
  }
}

@media only screen and (max-width: 1700px) {
  [data-scale="1.5"] #menu::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right: -64px;
    z-index: -1;
    backdrop-filter: url(/assets/pixelate.svg#pixelate) grayscale(100%) blur(2px);
    transition: margin-right 0.4s ease-in-out;
  }
  [data-menu-open="true"] #menu::before {
    margin-right: 0;
  }
  [data-scale="1.5"] #menu .menuSection button {
    backdrop-filter: blur(6px) brightness(50%);
  }
  [data-scale="1.5"] #menu .menuSection button:hover, [data-scale="1.5"] #menu .menuSection button:focus {
    backdrop-filter: blur(10px) brightness(25%);
  }
  [data-scale="1.5"] #menu .settingsRow:nth-child(1) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff0000, 0 0 32px #ff0000, 0 0 48px #ff0000;
  }
  [data-scale="1.5"] #menu .settingsRow:nth-child(2) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #00ff00, 0 0 32px #00ff00, 0 0 48px #00ff00;
  }
  [data-scale="1.5"] #menu .settingsRow:nth-child(3) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #0000ff, 0 0 32px #0000ff, 0 0 48px #0000ff;
  }
  [data-scale="1.5"] #menu .settingsRow:nth-child(4) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff00ff, 0 0 32px #ff00ff, 0 0 48px #ff00ff;
  }
  [data-scale="1.5"] #logo svg .c {
    opacity: 0;
  }
  [data-scale="1.5"] #logo svg .c1 {
    opacity: 1;
  }
}

@media only screen and (max-width: 2000px) {
  [data-scale="2"] #menu::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right: -64px;
    z-index: -1;
    backdrop-filter: url(/assets/pixelate.svg#pixelate) grayscale(100%) blur(2px);
    transition: margin-right 0.4s ease-in-out;
  }
  [data-menu-open="true"] #menu::before {
    margin-right: 0;
  }
  [data-scale="2"] #menu .menuSection button {
    backdrop-filter: blur(6px) brightness(50%);
  }
  [data-scale="2"] #menu .menuSection button:hover, [data-scale="2"] #menu .menuSection button:focus {
    backdrop-filter: blur(10px) brightness(25%);
  }
  [data-scale="2"] #menu .settingsRow:nth-child(1) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff0000, 0 0 32px #ff0000, 0 0 48px #ff0000;
  }
  [data-scale="2"] #menu .settingsRow:nth-child(2) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #00ff00, 0 0 32px #00ff00, 0 0 48px #00ff00;
  }
  [data-scale="2"] #menu .settingsRow:nth-child(3) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #0000ff, 0 0 32px #0000ff, 0 0 48px #0000ff;
  }
  [data-scale="2"] #menu .settingsRow:nth-child(4) label {
    text-shadow: 0 0 4px #000000, 0 0 8px #000000, 0 0 16px #000000, 0 0 24px #ff00ff, 0 0 32px #ff00ff, 0 0 48px #ff00ff;
  }
  [data-scale="2"] #logo svg .c {
    opacity: 0;
  }
  [data-scale="2"] #logo svg .c1 {
    opacity: 1;
  }
}

.canvas--p5_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  width: 500px;
  height: 50px;
  left: calc(50% - (500px /2));
  top: calc(50% - (50px /2));
}

#wrapper {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.75s ease-in-out;
}

[data-ready="true"] #wrapper {
  opacity: 1;
  pointer-events: all;
}
